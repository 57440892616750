
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}


.carousel-fade .carousel-item {
   opacity: 0;
   transition-duration: .7s;
   transition-property: opacity;
}

.carousel-fade  .carousel-item.active,
.carousel-fade  .carousel-item-next.carousel-item-left,
.carousel-fade  .carousel-item-prev.carousel-item-right {
    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade  .active.carousel-item-right {
    opacity: 0;
}

.carousel-fade  .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade  .active.carousel-item-prev {
   transform: translateX(0);
   transform: translate3d(0, 0, 0);
}

.carousel-inner .item,
.carousel-inner > .item > img, 
.carousel-inner > .item > a > img{
    margin:auto;
//     padding: 0px;
}
