// nav stuff
.navbar {
}


.topic_nav {
   .navbar-nav {
      flex-flow: row wrap !important;
   }
}


.top_nav {

   font-size: rem(18);

   .dropdown-menu {
      @extend .shadow_light;
   }

   .navbar-nav a.nav-link {
      color: $black !important;
//      @include opacity(0.9);
//      @extend .shadow_text;
   }

   .glyphicon {
      @include media-breakpoint-down(sm) {
         font-size: rem(24);
      }
   }
   .navbar-brand {
      display: none;
      @include media-breakpoint-down(sm) {
         display: inline;
      }
   }
   .search_app_mobile {
      @extend .rounded;
      width: 100%;
      padding: $medium-pad;
      background-color: lighten($robert_main, 15%);
      margin-top: $medium-pad;
      margin-left: -$medium-pad;
      margin-right: -medium-pad;
      margin-bottom: 0;
   }
}


ol.breadcrumb {
   font-size: rem(14);
   li,
   a,
   a:link {
      color: $robert_main_dark;
   }
}


// footer

.footer_nav,
.social_nav,
.teaser_row {
      font-size: rem(14);

   h3 {
      border-bottom: 1px solid $medium_grey;
      color: $white;
      font-size: rem(18);
      font-weight: 600;
      line-height: 27px;
      text-transform: uppercase;
      padding-top: 0;
      margin-top: 0;
//      padding: 20px 0 10px;
   }

   ul {
      list-style-type: none;
      margin-left: 0;
      padding-left: 0;
      margin-top: 15px;
      color: $white;

      li {
         a {
            padding: 0 0 5px 0;
            display: block;
         }
      }

   }
}


.footer_nav {
   & > .row {
      @include media-breakpoint-down(sm) {
         display: block;
      }
   }
}


