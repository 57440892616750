// footer

footer {

div.footer {

   & > div.row {
      background-color: $robert_main_light;
      &.lightened_foot {
         padding-top: $large-pad;
         background-color: lighten($robert_main_light,3%);
         z-index: 500;
         & > * {
            z-index: 550;
         }
      }
   }

   &.bow  {
      padding-top: 250px;
      overflow: hidden;
      position: relative;
      text-align: center;
   }

   &.bow:before, 
   &.bow:after {
      position: absolute;
      top: 12%;
      left: 55%;
      content: '';
//      border: 20px solid $robert_main_light;
      border: 185px solid $robert_main_light;
      width: 2900px;
      height: 1035px;
      margin-left: -1060px;
      border-radius: 50% / 50%;
      box-sizing: border-box;
      padding: 0;
      z-index: 0;
//      @include opacity(0.7);
   }

}
   .bottom_bar {
//      background-color: $bottom-panel-bg;
//      @extend .shadow_mini;

      .company {
         padding-top: $medium-pad;
         padding-bottom: $medium-pad;

         & > span {
            word-wrap: normal;
         }
//         @extend .shadow_text;
      }
   }


   margin-top: rem(50);
   @include media-breakpoint-down(md) {
      margin-top: $medium-pad;
   }
   @include media-breakpoint-down(sm) {
      margin-top: $small-pad;
   }

   .jumbotron-disabled {
      color: $medium-grey;
//      background-color: $robert_main;
//      @extend .shadow_mini;
//      @extend .shadow_text;
      a,
      a:link {
//         color: lighten($medium-grey, 15%);
         color: darken($medium-grey, 15%);
      }
      .footer_nav {
         margin-top: $medium-pad;
      }
      .social_nav {
         margin-top: $medium-pad;
      }
   }
   .col_help_nav {
//      @extend .right-horizontal;
   }

   .info {
      .row {
         margin-right: 0;
         margin-left: 0;
      }
    }

   .copyright {
      margin-top: $small-pad;
      font-size: rem(12);
      text-align: center;
      color: $medium-grey;
      a,
      a:link {
         color: $medium-grey;
         color: lighten($medium-grey, 15%);
      }
   }
}
