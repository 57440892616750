body {
font-family: "Open Sans", sans-serif;
}

.topic_nav {
   font-family: 'Roboto', sans-serif;
}
      
h1,h2,h3,h4,h5 {
//   color: $robert_main_dark;
   color: $black;
   font-style: italic;
}

h1,
h2 {
   @include media-breakpoint-down(sm) {
      font-size: 1.5em;
   }
}


.jumbotron {
   h1,h2,h3,h4,h5 {
      color: $white;
   }
}

// Social Media - Font Awesone Styles

.fa {
   padding: 20px;
   font-size: 30px;
   width: 50px;
   text-align: center;
   text-decoration: none;
   margin: 5px 2px;

   :hover {
      opacity: 0.7;
   }

   fa-facebook {
      background: #3B5998;
      color: white;
   }

   .fa-twitter {
      background: #55ACEE;
      color: white;
   }

   .fa-google {
      background: #dd4b39;
      color: white;
   }
}

.rdt {
   color: $rdt_color;
}


.bgf {
   color: $bgf_color;
}
