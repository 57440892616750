// site

* {
   box-sizing: border-box;
}


::selection {
   background-color: transparent;
}

::-moz-selection {
   background-color: transparent;
}

div#carousel1 {
    overflow: hidden;

    min-height: 379px;
    //height: 379px;

    @include media-breakpoint-down(lg) {
       min-height: 317px;
    }
    @include media-breakpoint-down(md) {
       min-height: 235px;
    }
    @include media-breakpoint-down(sm) {
       min-height: 107px;
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
}

main {
  margin-top: $medium-pad;
  h1.title {
     overflow-wrap: break-word;
  }
  img {
     max-width: 100%;
//     height: auto;
  }

}

