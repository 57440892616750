// lists | indexes

.feed_box {
   margin-bottom: $medium-pad;
}

.list_element {
   margin-bottom: $medium-pad;

   .podcasts_list_element {
   }
   .heading {
       .date {
          color: $medium-grey;
       }
    }
   .span_post {
      color: $medium-grey;
   }
}

.news_list {

   h3 {
      margin-top: $large-pad;
   }
}

.news_list,
.broadcast_list {
   margin-bottom: $large-pad;
}


.podcast_preview {
   margin-top: $large-pad;
}


.bt_sequence {
   padding-top: $medium-pad;
   padding-bottom: $medium-pad;
}



main ul:not([class="toc_list"],[class="ausstattung"],[class="features"]),
ul.list {
   list-style: none;
   padding: 0;
   li {
      padding-left: $medium-pad;
      margin-left: $medium-pad;
      &:before {
         @include font-mdi("\f142");
         margin-left: -$medium-pad; /* same as padding-left set on li */
         width: $medium-pad; /* same as padding-left set on li */
         margin-right: $small-pad;
      }
   }
}



ul.toc_list {
   list-style: none;
   padding: 0;
   li {
      padding-left: $medium-pad;
      margin-left: $medium-pad;
      &:before {
         @include font-mdi("\f13e");
         margin-left: -$medium-pad; /* same as padding-left set on li */
         width: $medium-pad; /* same as padding-left set on li */
         margin-right: $mini-pad;
      }
   }
}


ul.features {
   list-style: none;
   padding: 0;
   li {
      padding-left: $medium-pad;
      margin-left: $medium-pad;
      &:before {
         @include font-mdi("\f5e0");
         color: $robert_main_medium !important;
         margin-left: -$medium-pad; /* same as padding-left set on li */
         width: $medium-pad; /* same as padding-left set on li */
         margin-right: $mini-pad;
      }
   }
}

   
dt {
   padding-left: $medium-pad;

   &:before {
      @include font-mdi("\f60d");
      margin-left: -$medium-pad; /* same as padding-left set on li */
      width: $medium-pad; /* same as padding-left set on li */
   }
}



