// grid stuff */

.content {
   margin-top: $medium-pad;
//   flex-wrap: wrap;
}


div.teaser_row {
   margin-top: rem(48);
   z-index: 200;
   @include box-shadow(0, 3px, 3px, rgba(0, 0, 0, 0.1), $inset:false);
}

