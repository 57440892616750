$ease-break: cubic-bezier(0.07, 0.54, 0, 0.99);

$hard-site-url-prefix:	"http://zms3proj.zope.syndicat.com";

//path vars
$assetPath : "/theme_nd/style/images";
$font-path: "/theme_nd/style/fonts";
$fa-font-path: $font-path;


$base_color:		#f2ac49;
$second_color:		#f66933;

//$base_color:            #f66933;
//$second_color:          #f2ac49;

$primary:		$second_color;
$secondary:		$base_color;

// nd Theme
$robert_main:		$base_color;
$robert_main_dark:	darken($robert_main, 40%);
$robert_main_medium:	darken($robert_main, 15%);
$robert_main_light:	lighten($robert_main, 30%);


$body-bg:		#fbfefe;
$jumbotron-bg:		$robert_main;
$top-panel-bg:          $robert_main;
$bottom-panel-bg:       $robert_main;

$rdt_color:		#437A32;

$bgf_color:		#2b517a;


$white:           #FBFBFB;
$light-grey:      #E6E6E6;
$medium-grey:     #888888;
$grey:            #3c3c3b;
//$black:           rgb(24,24,26);
$black:           darken($robert_main, 98%);

$bg_grey:         #ebeced;

$orange:          #FF9900;
$green:           #0e7633;
$red:             #e03b0f;
$red_light:       lighten($red, 35%);

$second_full:	  $second_color;

$blue:            $second_color;



$odd:                   $light-grey;
$even:                  transparent;


// pads / sizes
$mini-pad: 0.25rem;
$small-pad: 0.5rem;
$medium-pad: 1rem;
$large-pad: 2rem;
$double-pad: 4rem;
$triple-pad: 8rem;

// font
$mini-font: 0.5rem;
$small-font: 0.75rem;
$medium-font: 1rem;
$large-font: 1.5rem;


$small-factor: 1;
$medium-factor: 2;
$large-factor : 3;

$mq-responsive: true;

$mq-breakpoints: (
        mobile:  320px,
        tablet:  740px,
        desktop: 980px,
        wide:    1300px,

  // Tweakpoints
        desktopAd: 810px,
        mobileLandscape: 480px
);

$mq-static-breakpoint: desktop;

// bootstrap Anpassungen
$dropdown-link-active-color:	$robert_main;
$dropdown-link-active-bg:	lighten($robert_main, 60%);

